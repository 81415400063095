import React from 'react';

import { Container, Heading } from '../../styles/styles';

const Contact = () => {
	return (
		<section className="py-20">
			<Container>
				<Heading>Pour aller plus loin</Heading>
				<div className="flex flex-wrap ">
					<div className="w-full lg:w-1/2 mb-8 lg:mb-0">
						<div className="max-w-lg">
							<p>
								Elément incontournable de la culture urbaine, le
								skateboard occupe une place toujours
								grandissante dans l’espace public, détournant le
								mobilier de la ville pour en faire un terrain de
								jeu, proposant un mode de déplacement doux, une
								nouvelle lecture de l’espace urbain. Pour
								promouvoir et encadrer ces pratiques, les villes
								aménagent des skateparks où les usagers se
								retrouvent, partagent, créent du lien. Les
								skateparks sont des «stades de skateboard», qui
								offrent un cadre sécurisé. Cependant, ces usages
								sont nés, se sont développés, et continuent à
								exister en «milieu naturel», c’est à dire dans
								la rue.
							</p>
							<p>
								Avec un nombre croissant d’usagers partout dans
								le monde, avec la perspective des JO de PARIS en
								2024, où le skateboard sera officiellement
								représenté, avec la transformation d’un
								épi-phénomène en sport de masse, le temps est
								révolu où l’on créait un skatepark en espérant
								sauvegarder le mobilier de la place de l’hotel
								de ville... Les usagers à roulettes sont
								partout, places publiques, parkings, pistes
								cyclables...
							</p>
							<p>
								Certains urbanistes et certaines collectivités
								en Europe ont bien compris ces enjeux, proposant
								d’intégrer ces nouveaux usagers aux aménagements
								urbains, avec un niveau de priorité adapté. On
								parle alors de «skate-urbanisme».
							</p>
							<p>
								Depuis quelques années déjà, Copenhague (DAN),
								Malmö (SUE), et plus récemment Paris (FR) et
								Melbourne (AUS), ont adopté cette politique
								d’intégration, qui favorise la pratique du
								skateboard en ville
							</p>
						</div>
					</div>
					<div className="w-full lg:w-1/2 px-4">
						<h3
							id="contact"
							className="mb-10 text-xl text-gray-600"
						>
							Nous nous tenons à votre disposition pour aborder
							ces sujets avec vous, et vous accompagner dans une
							politique de la ville innovante, pensée par et pour
							les usagers. Remplissez le formulaire cî-dessous
							pour nous contacter.
						</h3>
						<form action="#">
							<input
								className="w-full py-3 pl-3 mb-4 border rounded"
								type="text"
								placeholder="Nom"
							/>
							<input
								className="w-full py-3 pl-3 mb-4 border rounded"
								type="email"
								placeholder="Adresse E-mail"
							/>
							<textarea
								className="mb-4 w-full p-3 border rounded resize-none"
								id="1"
								name="message"
								cols="30"
								rows="10"
								placeholder="Votre Message..."
							></textarea>
							<button className="w-full inline-block px-6 py-3 mr-4 text-sm text-white font-bold leading-loose bg-gray-500 hover:bg-gray-600 rounded transition duration-200">
								Envoyer le message
							</button>
						</form>
					</div>
				</div>
			</Container>
		</section>
	);
};

export default Contact;

import React from 'react';
import { FaFacebook, FaInstagram } from 'react-icons/fa';

import { Container } from '../styles/styles';

let year = new Date().getFullYear();
const Footer = () => {
	return (
		<footer className="pt-20">
			<Container>
				<div className="flex flex-wrap items-center">
					<div className="w-full lg:w-1/2 mb-6 lg:mb-0">
						<p className="hidden lg:block text-sm text-gray-400">
							&copy; {year} - Hall04 Skateparks
						</p>
					</div>
					<div className="w-full lg:w-1/2 mb-4 lg:mb-0">
						<div className="flex lg:justify-end">
							<a
								className="inline-block mr-8"
								href="https://facebook.com/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<FaFacebook size={32} />
							</a>
							<a
								className="inline-block mr-8"
								href="https://intagram.com/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<FaInstagram size={32} />
							</a>
						</div>
					</div>
					<p className="lg:hidden text-sm text-gray-400">
						&copy; {year} - Hall04 Skateparks
					</p>
				</div>
			</Container>
		</footer>
	);
};

export default Footer;

import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import { navigate } from '@reach/router';

import { StaticImage } from 'gatsby-plugin-image';

import { Popover, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';

import Logo from '../images/hall04-logo.inline.svg';

import '../styles/global.css';

const navigation = [
	{ name: 'Presentation', href: 'presentation' },
	{ name: "L'équipe", href: 'team' },
	{ name: 'References', href: 'references' },
];

export default function Header() {
	const handleInAppNav = (e, href) => {
		e.preventDefault();
		console.log('yo', href);
		href && navigate(`#${href}`);
	};

	return (
		<div className="relative overflow-hidden">
			<div className="max-w-7xl mx-auto">
				<div className="relative z-10 pb-8 sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
					{/*<svg
            className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>*/}

					<Popover>
						<div className="relative pt-6 px-4 sm:px-6 lg:px-8">
							<nav
								className="relative flex items-center justify-between sm:h-10 lg:justify-start"
								aria-label="Global"
							>
								<div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
									<div className="flex items-center justify-between w-full md:w-auto">
										<Link to="/">
											<Logo className="w-full max-w-logo" />
										</Link>
										<div className="-mr-2 flex items-center md:hidden">
											<Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-light">
												<span className="sr-only">
													Open main menu
												</span>
												<MenuIcon
													className="h-6 w-6"
													aria-hidden="true"
												/>
											</Popover.Button>
										</div>
									</div>
								</div>
								<div className="hidden md:block md:ml-10 md:pr-4 md:space-x-4">
									{navigation.map(({ href, name }) => (
										<a
											key={name}
											href={`/${href}`}
											onClick={(e) =>
												handleInAppNav(e, href)
											}
											className="font-medium text-gray-600 hover:text-black"
										>
											{name}
										</a>
									))}
									<a
										href="/contact"
										className="font-medium text-blue-dark hover:text-blue-medium"
										onClick={(e) =>
											handleInAppNav(e, 'contact')
										}
									>
										Contactez Nous
									</a>
								</div>
							</nav>
						</div>

						<Transition
							as={Fragment}
							enter="duration-150 ease-out"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="duration-100 ease-in"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Popover.Panel
								focus
								className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
							>
								<div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
									<div className="px-5 pt-4 flex items-center justify-between">
										<div>
											<Logo className="w-full max-w-mobileLogo" />
										</div>
										<div className="-mr-2">
											<Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-light">
												<span className="sr-only">
													Close main menu
												</span>
												<XIcon
													className="h-6 w-6"
													aria-hidden="true"
												/>
											</Popover.Button>
										</div>
									</div>
									<div className="px-2 pt-2 pb-3 space-y-1">
										{navigation.map(({ name, href }) => (
											<a
												key={name}
												href={`/${href}`}
												onClick={(e) =>
													handleInAppNav(e, href)
												}
												className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
											>
												{name}
											</a>
										))}
									</div>

									<a
										href="/contact"
										className="block w-full px-3 py-6 text-center font-medium text-grey-600 bg-blue-light hover:bg-blue-dark hover:text-white"
										onClick={(e) =>
											handleInAppNav(e, 'contact')
										}
									>
										Contactez nous
									</a>
								</div>
							</Popover.Panel>
						</Transition>
					</Popover>

					<main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
						<div className="sm:text-center lg:text-left">
							<h1 className="mb-8 text-3xl md:text-4xl font-extrabold text-gray-800 uppercase">
								Nous dessinons et construisons des{' '}
								<span className="block text-blue-dark xl:inline">
									skateparks.
								</span>
							</h1>
							<p className="mt-3 text-base text-gray-600 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
								Nous integrons le mobilier urbain, blablabal.
								Qui irure qui lorem cupidatat commodo. Elit sunt
								amet fugiat veniam occaecat fugiat aliqua.
							</p>
							<div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
								<div className="rounded-md shadow">
									<a
										href="/contact"
										className="flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-gray-600 bg-blue-light hover:bg-blue-dark hover:text-white md:py-4 md:text-lg md:px-10"
										onClick={(e) =>
											handleInAppNav(e, 'contact')
										}
									>
										Contactez nous
									</a>
								</div>
							</div>
						</div>
					</main>
				</div>
			</div>
			<div className="lg:absolute lg:inset-y-0 lg:right-0 lg:top-0 lg:w-1/2">
				<StaticImage
					loading="eager"
					src="../images/header.jpg"
					alt="Photographie artistique de l'ombre d'une courbe dans un bowl de skateboard"
				/>
			</div>
		</div>
	);
}

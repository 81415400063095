import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Reference from '../ref';

import {
	Container,
	Heading,
	// WithCutcornerBL,
	// WithReverseCutcornerBL,
} from '../../styles/styles';

const References = () => {
	const { allReferencesJson, allFile } = useStaticQuery(graphql`
		query RefQuery {
			allReferencesJson {
				edges {
					node {
						id
						name
						size
						type
						description
						images {
							is_main
							src
							alt
						}
					}
				}
			}
			allFile(filter: { relativeDirectory: { regex: "/projects/" } }) {
				edges {
					node {
						id
						name
						childImageSharp {
							gatsbyImageData(layout: FULL_WIDTH)
						}
					}
				}
			}
		}
	`);

	return (
		<section className="relative py-20 bg-white">
			<Container>
				<div className="max-w-4xl ml-0 mx-auto">
					<Heading id="references">Nos Références</Heading>
				</div>
				<div className="flex flex-col gap-y-20">
					{allReferencesJson.edges.map(({ node }, i) => {
						return (
							<Reference
								node={node}
								allFile={allFile}
								index={i}
								key={`ref-${node.id}`}
							/>
						);
					})}
				</div>
			</Container>
		</section>
	);
};

export default References;

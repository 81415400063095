import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { Container, Heading, WithCutcornerBL } from '../../styles/styles';

const Team = () => {
	const { allTeamJson, allImageSharp } = useStaticQuery(graphql`
		query TeamQuery {
			allTeamJson {
				edges {
					node {
						id
						name
						titles
						content
						image {
							alt
							src
						}
					}
				}
			}
			allImageSharp(
				filter: {
					fixed: { originalName: { regex: "/JBPicot|MoganFavre/" } }
				}
			) {
				edges {
					node {
						gatsbyImageData(layout: FULL_WIDTH)
						parent {
							... on File {
								name
							}
						}
					}
				}
			}
		}
	`);

	return (
		<section className="relative py-20 bg-blue-light">
			<Container>
				<div className="max-w-4xl ml-0 mx-auto">
					<Heading id="team">L'Equipe</Heading>
				</div>
				<div className="grid grid-cols-1 md:grid-cols-2 md:gap-x-10 items-start">
					{allTeamJson.edges.map(
						(
							{ node: { id, name, titles, image, content } },
							idx
						) => {
							const currentImage = allImageSharp.edges.filter(
								(nde) => {
									return image.src.includes(
										nde.node.parent.name
									);
								}
							)[0];

							const poster = getImage(currentImage.node);

							return (
								<article
									key={id}
									className="flex flex-wrap"
									data-sal="slide-up"
									data-sal-delay={`${150 * (idx + 1)}`}
									data-sal-easing="ease"
								>
									<WithCutcornerBL className="w-full">
										<GatsbyImage
											image={poster}
											alt={image.alt}
											placeholder={'blurred'}
											data-sal="fade"
											data-sal-delay="250"
											data-sal-easing="ease"
										/>
									</WithCutcornerBL>
									<div className="w-full p-4 pl-0 md:-mt-10">
										<h3 className="mb-2 text-2xl font-extrabold text-gray-600">
											{name}
										</h3>
										<p className="mb-4 text-lg text-gray-600">
											{titles.map((title, i) => (
												<span
													key={`team-text-${i}`}
													className="block"
												>
													{title}
												</span>
											))}
										</p>
										<p
											className="mb-6 text-gray-600 leading-loose"
											dangerouslySetInnerHTML={{
												__html: content,
											}}
										></p>
									</div>
								</article>
							);
						}
					)}
				</div>
			</Container>
		</section>
	);
};

export default Team;

import React from 'react';
import { getImage } from 'gatsby-plugin-image';

import { StyledGatsbyImage } from '../styles/styles';

const Ref = ({ node, allFile, index }) => {
	const { name, size, type, description, images } = node;

	const mainImage = images.filter((img) => img.is_main)[0];
	const currentImage = allFile.edges.filter((nde) =>
		mainImage.src.includes(nde.node.name)
	)[0];
	const poster = getImage(currentImage.node);
	return (
		<div
			className="grid grid-cols-1 md:grid-cols-7 items-start gap-x-4 md:gap-x-8"
			data-sal="slide-up"
			data-sal-delay="100"
			data-sal-easing="ease"
		>
			<div
				className={
					index % 2 === 0
						? `md:order-last md:col-span-4`
						: `md:col-span-4`
				}
				data-sal="fade"
				data-sal-delay="250"
				data-sal-easing="ease"
			>
				<StyledGatsbyImage
					image={poster}
					alt={mainImage.alt}
					className="min-h-1/2screen"
				/>
			</div>
			<div className="mt-4 md:mt-0 md:col-span-3">
				<h3 className="mb-2 text-2xl font-extrabold text-gray-600">
					{name}
				</h3>
				<ul className="mb-4 text-lg text-gray-600">
					{size && (
						<li>
							<strong>Superficie:</strong> {size}m2
						</li>
					)}
					{type && (
						<li>
							<strong>Typologie du skatepark:</strong> {type}
						</li>
					)}
				</ul>
				<p
					className="mb-6 text-gray-600 leading-loose"
					dangerouslySetInnerHTML={{
						__html: description,
					}}
				></p>
			</div>
		</div>
	);
};

export default Ref;

import * as React from 'react';

import Header from '../components/header';
import Footer from '../components/footer';

import Presentation from '../components/sections/presentation';
import References from '../components/sections/references';
import Team from '../components/sections/team';
import Contact from '../components/sections/contact';

import { Content } from '../styles/styles';

// markup
const IndexPage = () => {
	return (
		<main>
			<title>Hall 04 et Compagnie - Creation et intégration de mobilier urbain et skateparks</title>
			<Header />
			<Content>
				<Presentation />
				<Team />
				<References />
				<Contact />
			</Content>
			<Footer />
		</main>
	);
};

export default IndexPage;

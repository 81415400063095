import React from 'react';

import { Container, Heading } from '../../styles/styles';

const Presentation = () => {
	return (
		<section className="py-20">
			<Container>
				<div className="max-w-3xl ml-0 mx-auto">
					<Heading id="presentation">Presentation</Heading>
					<p>
						Depuis longtemps déjà, le skateboard, le BMX et le
						roller s’approprient la micro- architecture des villes,
						pour transformer le paysage urbain en un lieu de
						découverte ludique.
					</p>
					<p>
						Le skatepark, 1ère étape d’une réponse urbanistique de
						la collectivité pour offrir un lieu sécurisé et adapté
						au développement de ces usages, devient un lieu de
						rencontre, le point de rendez-vous où l’on passe une
						heure ou l’après-midi, où l’on rencontre les parents et
						les enfants le matin pour les sessions pédagogiques
						encadrées, les jeunes adultes l’après-midi, et où tout
						le monde se retrouve en fin de journée pour les sessions
						plus «engagées».
					</p>
					<p>
						Le skatepark est un lieu convivial, site de convergence
						d’un public intergénérationnel large et varié, de
						l’usager à l’accompagnant, du spectateur au riverain...
					</p>
					<p>
						Hall04 était, dans les années 90, la 1ère école de
						skateboard indoor auto-construite en France. Nous avons
						conçu et construit, puis géré la structure pendant plus
						de 10 ans, nous procurant ainsi une expérience et une
						culture de la pédagogie et du «design efficace» unique
						en France. Ainsi, outre les aspects architecturaux et
						paysagers, indispensables au bon dialogue de
						l’équipement avec son contexte, l’agence Hall04 propose
						une approche transversale unique en France, basée sur
						une connaissance pratique de la pédagogie des sports
						concernés, pour la création de sites accueillants pour
						tous les publics amenés à fréquenter nos skateparks.
					</p>
					<p>
						Notre maîtrise des données culturelles et historiques
						des usages ciblés, augmentée de 20 années d’expérience
						dans la conception et la réalisation de skateparks
						publics ou privés, nous permet de proposer une
						compréhension fine des contextes explorés, la pertinence
						de choix maîtrisés, pour l’élaboration de projets exem-
						plaires, originaux et partagés. Usagers des skateparks
						que nous fabriquons, nous portons une attention toute
						particulière à la sélection des entreprises amenées à
						réaliser nos projets, afin de garantir une continuité de
						compétences, indispensable, de la planche à dessin au
						talochage final.
					</p>
					<p>
						Nous prétendons créer des sites attractifs, innovants,
						intégrés et valorisants pour les usagers autant que pour
						les prescripteurs / financeurs. En collaboration avec
						des partenaires qualifiés, nous travaillons au service
						de la commande publique et privée sur l’ensemble du
						territoire européen. Les acteurs industriels de la
						"glisse" ne s'y trompent pas, nous avons le plaisir de
						compter parmi nos clients des entreprises prestigieuses
						telles : Quiksilver / Volcom / Billabong / DaKine /
						Element Skateboards, pour lesquelles nous avons réalisé
						des skateparks en bois ou en béton, qui sont des
						références sur le territoire européen aujourd'hui
					</p>
				</div>
			</Container>
		</section>
	);
};

export default Presentation;
